<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
  <div class="form-group mb-0 break-spaces">
      <TextArea
        id="testeSensibilidadeMimicaFacial"
        :value="form.fields.testeSensibilidadeMimicaFacial"
        @blur="onBlur(form)"
        @inputTextArea="el => updateForm('testeSensibilidadeMimicaFacial', el)"
        :readonly="!this.validCanEdit()"
        rows="1"
        :placeholder="this.form.fields.testeSensibilidadeMimicaFacial ? this.form.fields.testeSensibilidadeMimicaFacial : 'Descrever'"
      />
    </div>
  </FormWrapper>
</template>
  
  <script>
  import { mapState, mapActions } from 'vuex'
  import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

  export default {
    components: {
      FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
      TextArea: () => import('@/components/General/TextArea'),
    },
    computed: {
      ...mapState({
        canEdit: state => state.attendance.canEdit,
        canDefaultForms: state => state.attendance.canDefaultForms,
        form: state => state.attendance.form.testeSensibilidadeMimicaFacial,
        attendance: state => state.attendance.attendance,
        selectedForm: state => state.attendance.form.selected,
      }),
    },
    mixins: [attendanceDataMixin('medicalRecordsStore')],
    data() {
      return {
        loading: false,
        ishihara: null
      }
    },
    methods: {
      ...mapActions('attendance', ['editDefaultForms']),
      ...mapActions('attendance/form', ['handleActiveModule', 'updateMedicalRecord', 'destroyMedicalRecord', 'defaultUpdateForm']),
      ...mapActions('attendance/form/testeSensibilidadeMimicaFacial', ['handleFields', 'handleProps']),
      updateForm(key, value) {
        this.handleFields({ key, value })
        this.updateMedicalRecord(this.form)
      },
      onBlur(value) {
        this.updateMedicalRecord(value)
        if(this.canDefaultForms) {
          this.defaultUpdateForm(value)
          this.$toast.success('Campo teste de sensibilidade mímica facial salvo com sucesso')
        }
      },
      hideForm(){
        this.handleProps({ key: 'showing', value: false })
        this.destroyMedicalRecord(this.form)
        Object.keys(this.form.fields).forEach(key => {
          this.form.fields[key] = null
        });
      },
      validCanEdit() {
        if(this.canDefaultForms === true || this.canEdit === true){
          return true
        } else {
          return false
        }
      }
    },
    watch: {
      selectedForm() {
        const form = this.selectedForm.forms.find(f => f.id === 'teste-sensibilidade-mimica-facial');

        if (form && form.default_text && this.canEdit) {
          setTimeout(() => {
            this.updateForm('testeSensibilidadeMimicaFacial', form.default_text)
            }, "3000")

        }
      }
    }
  }
</script>
<style lang="scss" scoped>

.subtitle {
    color: var(--type-active, #525C7A);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    margin-top: 0.6rem;
}
</style>